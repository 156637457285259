import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a className="sel" href="/kde_kupit/">
                    KDE KÚPIŤ{" "}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/kde_kupit/" className="sel">
              {" "}
              &gt; KDE KÚPIŤ{" "}
            </a>
          </div>
          <div id="page_content" className="partner">
            <div className="rel">
              <div className="wysiwyg">
                <p>
                  <img
                    alt
                    src="/web/kcfinder/uploads/images/sk/Kde_kupit_SK.png"
                    style={{
                      width: "358px",
                      height: "71px"
                    }}
                  />
                </p>
                <p>&nbsp;</p>
                <div>
                  Lieky značky OLYNTH<sup>®</sup> môžete zakúpiť v širokej sieti
                  kamenných a on-line lekární. Nižšie uvádzame niektoré z
                  možností on-line nákupu, z ktorých si môžete vybrať*.
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>Upozornenie</strong>
                </div>
                <div>
                  Kliknutím na logo lekárne vstupujete na stránky, za obsah
                  ktorých spoločnosť JNTL Consumer Health (Slovakia) s.r.o.&nbsp;nenesie
                  žiadnu zodpovednosť a na ktorú sa nevzťahujú{" "}
                  <a href="/zasady_ochrany_osobnych_udajov/" target="_blank">
                    Zásady ochrany osobných údajov
                  </a>
                  &nbsp;stránky www.olynth.sk. Odporúčame Vám, aby ste si
                  prečítali zásady ochrany osobných údajov každej stránky, ktorú
                  navštívite.
                </div>
                <div>&nbsp;</div>
              </div>
              {/*<span className="partner_man" />*/}
              <div>
                <a
                  href="http://www.pilulka.sk/olynth"
                  className="box"
                  target="_blank"
                >
                  <div className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/partnerzy/Pilulka.new.png)"}}></div>
                  <div className="name">Pilulka </div>
                </a>
                <a
                  href="https://www.drmax.sk/olynth"
                  className="box"
                  target="_blank"
                >
                  <div className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/partnerzy/drmax.png)"}}></div>
                  <div className="name">Dr.Max </div>
                </a>
                <a
                  href="https://www.benulekaren.sk/vyhladavanie?q=olynth&category=other"
                  className="box"
                  target="_blank"
                >
                  <div className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/partnerzy/_logo_benu.png)"}}></div>
                  <div className="name">BENU lekáreň </div>
                </a>
                <a
                  href="https://www.vasalekaren.sk/olynth-m177"
                  className="box"
                  target="_blank"
                >
                  <div className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/partnerzy/Vasalekaren_100x100.png)"}}></div>
                  <div className="name">Vaša Lekáreň </div>
                </a>
                <a
                  href="https://etabletka.sk/?s=olynth"
                  className="box"
                  target="_blank"
                >
                  <div className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/partnerzy/etabletka.png)"}}></div>
                  <div className="name">eTabletka </div>
                </a>
                <a
                  href="https://www.mojalekaren.sk/olynth/"
                  className="box"
                  target="_blank"
                >
                  <div className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/partnerzy/MojaLekarenlogo.png)"}}></div>
                  <div className="name">MojaLekáreň </div>
                </a>
                <div className="clear" />
              </div>
              * Zoznam partnerov / lekární je len demonštratívny. Spoločnosť
              JNTL Consumer Health (Slovakia) s.r.o. nezodpovedá za dostupnosť daných
              liekov u týchto partnerov.{" "}
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
